import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
import { jwtDecode } from 'jwt-decode';
import { routesPaths } from '../../app.routes';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authToken: string | null;
  private role: string | null;
  constructor(private navService: NavigationService) {}

  logout() {
    this.deleteToken();
    this.navService.navigate(routesPaths.login);
  }

  setToken(token: string) {
    this.authToken = token;
    sessionStorage.setItem('authToken', this.authToken);
    this.setRole(this.authToken);
  }

  setRole(token: string) {
    const decodedToken = jwtDecode(token);
    this.role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  }

  getToken() {
    return this.authToken;
  }

  getTokenFromStorage() {
    this.authToken = sessionStorage.getItem('authToken');
    if (this.authToken) {
      this.setRole(this.authToken);
    }
  }

  getRole() {
    return this.role;
  }

  deleteToken() {
    this.authToken = null;
    this.role = null;
    sessionStorage.removeItem('authToken');
  }

  handleRequestErrors(err) {
    if (err.status === 401) {
      this.navService.navigate(routesPaths.login);
    }
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef} from "@angular/material/dialog";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatOption, MatSelect, MatSelectModule} from "@angular/material/select";
import {regexPatterns} from "../../../../shared/utils/regex";
import {AgentsRepository} from "../../../../shared/repositories/agents.repository";

@Component({
  selector: 'app-agent-dialog',
  standalone: true,
  imports: [
    MatSelectModule,
    MatError,
    MatFormField,
    MatHint,
    MatIcon,
    MatInput,
    MatLabel,
    MatProgressSpinner,
    ReactiveFormsModule,
    MatIconButton,
    MatDialogActions,
    MatButton,
    MatSlideToggle,
    MatSelect,
    MatOption
  ],
  templateUrl: './agent-dialog.component.html',
  styleUrl: './agent-dialog.component.scss'
})
export class AgentDialogComponent implements OnInit {
  form: FormGroup;
  isFormSubmitted: boolean = false;
  statesList: string[] = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TT', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AgentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private agentsRepository: AgentsRepository
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [this.data.type === 'create' ? '' : this.data.agent.name, [Validators.required]],
      email: [this.data.type === 'create' ? '' : this.data.agent.email, [Validators.required, Validators.pattern(regexPatterns.email)]],
      npn: [this.data.type === 'create' ? '' : this.data.agent.npn, [Validators.required, Validators.pattern(regexPatterns.onlyNumbers)]],
      phone: [this.data.type === 'create' ? '' : this.data.agent.phone, [Validators.required, Validators.pattern(regexPatterns.phone)]],
      isActive: [this.data.type === 'create' ? true : this.data.agent.isActive],
      stateLicenses: [this.data.type === 'create' ? [] : this.data.agent.licensedInStates],
      distributionId: [this.data.type === 'create' ? '' : this.data.distributions.find((i) => i.value === this.data.agent.distributionName).key, [Validators.required]],
    });
  }

  onCanceled(): void {
    this.dialogRef.close();
  }

  submit() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.isFormSubmitted = true;
    if (this.form.valid) {
      const requestData = this.data.type === 'edit' ? {...this.form.value, id: this.data.agent.id} : this.form.value;
      this.agentsRepository.createEditAgent(requestData).subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          if (err.error.errors['Email'].length) {
            this.form.get('email').setErrors({notUnique: true});
          }
        }
      });
    }
  }
}

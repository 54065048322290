import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef} from "@angular/material/dialog";
import {BillsRepository} from "../../../../shared/repositories/bills.repository";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatError, MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {provideNativeDateAdapter} from "@angular/material/core";
import {DatePipe} from "@angular/common";
import {IBill} from "../../../../shared/models/bills.interface";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-bill-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  templateUrl: './bill-dialog.component.html',
  styleUrl: './bill-dialog.component.scss',
  providers: [provideNativeDateAdapter(), DatePipe],
})
export class BillDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isFormSubmitted: boolean = false;
  startDateFilter = this.getStartDateFilter.bind(this);
  endDateFilter = this.getEndDateFilter.bind(this);
  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<BillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private billsRepository: BillsRepository,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      id: [this.data.type === 'create' ? '' : this.data.bill.id],
      name: [this.data.type === 'create' ? '' : this.data.bill.name, [Validators.required]],
      nPayPeriod: [this.data.type === 'create' ? '' : this.data.bill.nPayPeriod, [Validators.required]],
      startDate: [this.data.type === 'create' ? '' : this.data.bill.startDate, [Validators.required]],
      endDate: [{value: this.data.type === 'create' ? '' : this.data.bill.endDate, disabled: !this.data?.bill?.startDate}],
      groupSize: [this.data.type === 'create' ? '' : this.data.bill.groupSize, [Validators.required]],
      loomisNum: {value: this.data.type === 'create' ? '' : this.data.bill.loomisNum, disabled: true},
    });

    this.form.get('startDate').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((startDate) => {
      if (startDate) {
        this.form.get('endDate').enable();
      } else {
        this.form.get('endDate').reset();
        this.form.get('endDate').disable();
      }
    });
  }

  onCanceled(): void {
    this.dialogRef.close();
  }

  submit() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.isFormSubmitted = true;
    if (this.form.valid) {
      let requestData: IBill = this.generateRequestData();
      this.billsRepository.createBill(requestData).subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  generateRequestData (): IBill {
    const requestData: IBill = {
      name: this.form.value.name,
      nPayPeriod:  this.form.value.nPayPeriod,
      startDate: this.datePipe.transform(this.form.value.startDate, 'yyyy-MM-dd'),
      groupSize: this.form.value.groupSize
    }
    if (this.form.value.endDate) {
      requestData.endDate = this.datePipe.transform(this.form.value.endDate, 'yyyy-MM-dd');
    }
    if (this.data.type === 'edit') {
      requestData.id = this.form.value.id;
      requestData.loomisNum = this.form.value.loomisNum;
    }
    return requestData;
  }

  getStartDateFilter(d: Date | null): boolean {
    if (!d) return false;
    const day = d.getDate();
    return day === 1;
  }

  getEndDateFilter(d: Date | null): boolean {
    const startDate = new Date(this.form.get('startDate')?.value);
    if (!startDate || !d) return false;
    return d > startDate;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
